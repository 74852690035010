<template>
    <div v-if="award.disabled">
        This award is not ready for voting yet.
    </div>

    <div v-else>
        <header>
            <aside>
                <h4>{{ award.name }}</h4>
                <h5>{{ award.description }}</h5>
            </aside>
        </header>

        <DsInlineAlert
            v-if="getUserVote({ hackathonId: hackathon.id, awardId: awardIndex, userId: user.uid })"
            type="good"
            class="vote-alert"
        >
            <p>You voted for:</p>
            <p><strong>{{ getProject(getUserVote({ hackathonId: hackathon.id, awardId: awardIndex, userId: user.uid }).projectId).name }}</strong></p>
        </DsInlineAlert>

        <DsInlineAlert
            v-else
            type="subtle"
            class="vote-alert"
        >
            Click on a project to vote
        </DsInlineAlert>

        <DsConfirmDialog
            :is-open="isOpen"
            title="Confirm vote"
            :message="confirmVoteMessage"
            confirm-label="Vote"
            cancel-label="Cancel"
            @confirm="castVote"
            @cancel="cancelVote"
        />

        <div
            v-if="!getUserVote({ hackathonId: hackathon.id, awardId: awardIndex, userId: user.uid })"
            class="projects-container"
        >
            <ProjectsList
                v-if="hackathonProjects.length"
                :projects="hackathonProjects"
                compact
                @selected="confirmVote"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ProjectsList from '@/components/ProjectsList';

export default {
    components: {
        ProjectsList,
    },

    props: {
        award: Object,
        awardIndex: Number,
    },

    data() {
        return {
            isOpen: false,
            selectedProject: null,
        };
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            getUserVote: 'votes/getUserVote',
            getProject: 'projects/getProject',
            getHackathon: 'hackathons/getHackathon',
            getProjectsByHackathon: 'projects/getProjectsByHackathon',
        }),

        confirmVoteMessage() {
            return this.selectedProject?.name
                ? `You are about to vote for "${this.selectedProject.name}"`
                : '';
        },

        hackathonProjects() {
            return this.getProjectsByHackathon(this.hackathon?.id);
        },

        hackathonId() {
            return this.$route.params?.id;
        },

        hackathon() {
            return this.hackathonId ? this.getHackathon(this.hackathonId) : this.getActiveHackathon;
        },
    },

    methods: {
        cancelVote() {
            this.isOpen = false;
        },

        confirmVote(project) {
            this.selectedProject = project;
            this.isOpen = true;
        },

        userIsMemberOf(project) {
            const userId = this.user.uid;

            return project.members.includes(userId) || project.teamLead === userId;
        },

        castVote() {
            if (this.award?.disableOwnProjectVoting && this.userIsMemberOf(this.selectedProject)) {
                this.$toast({ message: 'You cannot vote for your own project for this award!' });
            } else {
                this.$store.dispatch('votes/ADD_VOTE', {
                    hackathonId: this.hackathon.id,
                    awardId: this.awardIndex,
                    projectId: this.selectedProject.id,
                    userId: this.user.uid,
                });
            }

            this.isOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vote-alert {
    margin-top: $spacing-200;
}

.projects-container {
    overflow: auto;
    max-height: calc(100vh - 292px);
    @include custom-scroll-bar;
}
</style>
