<template>
    <div>
        <DsSpinner v-if="loading" />

        <div v-else-if="!user && $route.params.id">
            <DsInlineAlert
                leading-icon
                type="warning"
            >
                User not found. If this is your profile, you can add yourself to the system using the button below.
            </DsInlineAlert>

            <div>New user will have the following information:</div>
            <pre>{{ authUser }}</pre>

            <DsOutlineButton @click="createUser">
                Create user...
            </DsOutlineButton>
        </div>

        <div v-else-if="!$route.params.id">
            <DsInlineAlert
                leading-icon
                type="warning"
            >
                User not found.
            </DsInlineAlert>
        </div>

        <template v-else>
            <div class="profile-info">
                <UserAvatar
                    style="display: block; margin: 0 auto;"
                    :size="96"
                    :user="user"
                />

                <h3>
                    {{ user.name }}
                </h3>

                <p>Joined {{ formatDate(user.creationTime) }}</p>

                <p>Email: {{ user.email }}</p>
                <p v-if="user.gitHubHandle">
                    Github: {{ user.gitHubHandle }}
                </p>

                <DsOutlineButton
                    v-if="canEdit"
                    @click="$router.push({ name :'user.edit' })"
                >
                    <DsStatus v-if="isAdmin" emphasis="high">
                        Admin
                    </DsStatus>

                    Edit
                </DsOutlineButton>
            </div>

            <h3 v-if="myProjects.length">
                Projects
            </h3>

            <ProjectsList
                v-if="myProjects.length"
                :projects="myProjects"
                @selected="(project) => $router.push({ name: 'project', params: { id: project.id} })"
            />
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import ProjectsList from '@/components/ProjectsList';
import UserAvatar from '@/components/UserAvatar';
import '@/assets/images/page-not-found.svg';

export default {
    components: {
        ProjectsList,
        UserAvatar,
    },

    props: {
        editing: Boolean,
    },

    data() {
        return {
            localUser: {},
            gitHubHandle: '',
            saving: false,
            loading: true,
        };
    },

    created() {
        this.loadUser();
    },

    watch: {
        '$route.params.id': {
            handler() {
                this.loadUser();
            },
        },
    },

    computed: {
        canEdit() {
            return this.isAdmin || this.userId === this.$route.params?.id;
        },

        myProjects() {
            return this.getProjectsByUser(this.$route.params?.id);
        },

        ...mapGetters({
            getProjectsByUser: 'projects/getProjectsByUser',
        }),

        ...mapState({
            userId: ({ auth }) => auth.user.uid,
            authUser: ({ auth }) => auth.user,
            user: ({ users }) => users.user,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),
    },

    methods: {
        formatDate(date) {
            return moment(date).fromNow();
        },

        async loadUser() {
            if (!this.$route.params?.id) {
                this.loading = false;

                return;
            }
            this.loading = true;
            await this.$store.dispatch('users/LOAD_USER', this.$route.params?.id)
                .catch((e) => {
                    this.$error({ message: `Error loading user: ${e}` });
                });
            this.loading = false;
        },

        async createUser() {
            this.loading = true;
            await this.$store.dispatch('users/CREATE_USER', this.authUser)
                .catch((e) => {
                    this.$error({ message: `Error creating user: ${e}` });
                });
            this.loading = false;
        },

        edit() {
            this.$refs.editProfileModal.open();
        },
    },
};

</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/styles/empty";

    .date-joined {
        margin-top: $spacing-200;
    }

    .user-page {
        display: grid;
        margin: $spacing-200;
        overflow: hidden;
        background: $color-white;
        border-radius: $border-radius;
    }

    .scroll-wrap {
        height: calc(100vh - 120px);
        overflow: auto;
        display: flex;
        flex-direction: column;
    }

    .profile-info {
        text-align: center;
        justify-content: center;
        display: grid;
        gap: $spacing-200;
    }
</style>
