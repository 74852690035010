<template>
    <div :class="['project-details', { padded }]">
        <project-images v-if="hasImages" :project="project" />

        <h2 :class="['project-name', { winner, 'no-image': !project.thumbnail }]">
            {{ project.name }}
        </h2>

        <div class="project-actions">
            <a
                v-if="project.slackChannelId"
                :href="`https://keap.slack.com/archives/${project.slackChannelId}`"
                target="_blank"
            >
                <DsOutlineButton>
                    Slack Channel
                </DsOutlineButton>
            </a>

            <DsOutlineButton
                v-if="isProjectOwner || isAdmin"
                @click="$router.push({ name: isIdea ? 'ideas.edit' : 'projects.edit', params: { id: project.id } })"
            >
                <DsStatus v-if="isAdmin && !isProjectOwner" emphasis="high">
                    Admin
                </DsStatus>
                Edit
            </DsOutlineButton>

            <DsFilledButton
                v-if="showJoinButton"
                :leading-icon="isIdea ? 'lightbulb' : 'add'"
                @click="promptJoinProject"
            >
                {{ isIdea ? 'Use idea' : 'Join' }}
            </DsFilledButton>
        </div>

        <DsInlineAlert v-if="teamMembers.length" leading-icon="users" type="subtle">
            <h5>Project team</h5>
            <ProjectTeamMembers :project="project" stacked />
        </DsInlineAlert>

        <section v-if="award.name">
            <DsInlineAlert
                leading-icon="award-fill"
                type="warning"
                class="award-alert"
            >
                <h5>Project accolades</h5>
                {{ award.name }}
            </DsInlineAlert>
        </section>

        <DsInlineAlert v-if="project.shortDescription" leading-icon="note" type="subtle">
            <h5>Project description</h5>
            <p>
                <vue-markdown :source="project.shortDescription" class="markdown" />
            </p>

            <template v-if="project.longDescription">
                <h5>Business value</h5>

                <p>
                    <vue-markdown :source="project.longDescription" class="markdown" />
                </p>
            </template>

            <template v-if="hasTags">
                <h5>Tags</h5>

                <div class="project-tags">
                    <DsChip
                        v-for="(tag, i) in project.tags"
                        :key="i"
                        class="chip"
                        :label="getTag(tag).name"
                        readonly
                    >
                        {{ getTag(tag).name }}
                    </DsChip>
                </div>
            </template>
        </DsInlineAlert>

        <DsInlineAlert v-if="project.notes" leading-icon="note" type="warning">
            <h5>Notes</h5>

            <vue-markdown :source="project.notes" class="markdown" />
        </DsInlineAlert>

        <DsInlineAlert v-if="project.rolesWanted.length" leading-icon="life-buoy" type="good">
            <h5>Roles wanted</h5>

            {{ rolesWanted }}
        </DsInlineAlert>

        <DsInlineAlert v-if="project.ideaAuthor" leading-icon="lightbulb" type="warning">
            <h5>Idea suggested by:</h5>

            <span class="idea-author">
                <router-link
                    :to="{ name: 'user', params: { id: getUser(project.ideaAuthor).id || 0 } }"
                    class="idea-author"
                >
                    <UserAvatar
                        :size="44"
                        :user="getUser(project.ideaAuthor)"
                    />
                </router-link>

                {{ getUser(project.ideaAuthor).name }}
            </span>
        </DsInlineAlert>

        <ProjectComments :project="project" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { roles } from '@/components/project/constants';
import cloneDeep from 'lodash.clonedeep';
import ProjectImages from '@/components/project/ProjectImages';
import ProjectTeamMembers from '@/components/ProjectTeamMembers';
import ProjectComments from '@/components/project/ProjectComments';
import VueMarkdown from 'vue-markdown';
import UserAvatar from '@/components/UserAvatar';

export default {
    components: {
        VueMarkdown,
        ProjectImages,
        ProjectTeamMembers,
        ProjectComments,
        UserAvatar,
    },

    props: {
        project: Object,
        padded: Boolean,
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            isAdmin: ({ auth }) => auth.user.isAdmin,
        }),

        ...mapGetters({
            winningProjectIds: 'hackathons/winningProjectIds',
            commentsByDate: 'projects/commentsByDate',
            getAwardByProject: 'projects/getAwardByProject',
            getUser: 'users/getUser',
            getTags: 'tags/getTags',
            getTag: 'tags/getTag',
            activeHackathon: 'hackathons/getActiveHackathon',
        }),

        teamMembers() {
            return [...new Set([this.project.teamLead, ...this.project.members])]?.filter((member) => member);
        },

        hasTags() {
            return this.project?.tags?.length;
        },

        rolesWanted() {
            return this.project?.rolesWanted?.map((role) => roles[role])?.join(', ');
        },

        teamLead() {
            return this.getUser(this.project?.teamLead);
        },

        isIdea() {
            return !this.project?.hackathonId;
        },

        award() {
            return this.getAwardByProject(this.project.id).award;
        },

        filteredMembers() {
            return this.project?.members?.filter((uid) => this.getUser(uid)?.name);
        },

        isProjectOwner() {
            const isTeamMember = this.project?.members?.includes(this.user?.uid);
            const isTeamLead = this.project?.teamLead === this.user?.uid;

            return isTeamMember || isTeamLead;
        },

        isProjectHackathonActive() {
            return this.activeHackathon?.id === this.project.hackathonId;
        },

        winner() {
            return this.winningProjectIds.includes(this.project.id);
        },

        hasImages() {
            return Boolean(this.project?.photos?.length > 0 || this.project?.thumbnail);
        },

        showJoinButton() {
            return this.isIdea || (!this.isProjectOwner && this.isProjectHackathonActive);
        },
    },

    methods: {
        getUserName(uid) {
            return this.getUser(uid)?.name;
        },

        getUserPhotoUrl(uid) {
            return this.getUser(uid).photoUrl;
        },

        promptJoinProject() {
            this.$confirm({
                title: `Join "${this.project.name}"`,
                message: 'Are you sure you want to join this project?',
                confirmLabel: 'Yes, join!',
            })
                .then(() => {
                    this.joinProject();
                })
                .catch(() => {});
        },

        async joinProject() {
            if (!this.activeHackathon) return;

            const project = cloneDeep(this.project);
            const isIdea = !project.hackathonId;

            project.members.push(this.user.uid);

            const payload = {
                projectId: project.id,
                project: {
                    ...project,
                    hackathonId: this.activeHackathon?.id || '',
                },
            };

            await this.$store.dispatch('projects/SAVE_PROJECT', payload)
                .catch((e) => {
                    this.$error({ message: `Error joining project: ${e}` });
                });

            this.$toast({ headline: isIdea ? 'Project created' : 'Joined project' });

            if (isIdea) this.$router.push({ name: 'project', params: { id: project.id } });
        },
    },
};
</script>

<style lang="scss" scoped>
.project-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include custom-scroll-bar;

    &.padded {
        padding: $spacing-200;
    }
}

.project-name {
    text-align: center;
    margin-bottom: $spacing-200;

    &.winner {
        font-weight: bold;
    }

    &.no-image {
        padding: $spacing-500 0;
        background-color: $color-gray-050;
        border-radius: $border-radius;
    }
}

.project-actions {
    display: flex;
    grid-gap: $spacing-200;
    align-items: center;
    justify-content: center;
    margin-bottom: $spacing-200;
}

.role-chip {
    float: left;
    margin-left: $spacing-200;
}

.project-tags {
    margin-bottom: $spacing-200;
    grid-gap: $spacing-100;
    display: inline-flex;
}

.idea-author {
    margin: $spacing-100 0 0 $spacing-100;
    display: flex;
    align-items: center;
    gap: $spacing-200;
}
</style>
